/*css*/
import './App.css';

/*React*/
import { lazy } from 'react';
import {BrowserRouter,Routes,Route} from "react-router-dom";

/*pages*/

const Index = lazy(() => import("./pages/index"));
const Web = lazy(() => import("./pages/web"));
const Contact = lazy(() => import("./pages/Contact"));
const Gallery = lazy(() => import("./pages/Gallery"));
const Login = lazy(() => import("./pages/login"));
const Admin = lazy(() => import("./pages/admin"));
const Admin_setProyect = lazy(() => import("./pages/Admin_setProyect"));
const Admin_setSkill = lazy(() => import("./pages/Admin_setSkill"));
const Admin_setImages = lazy(() => import("./pages/Admin_setImages"));
const CV = lazy(() => import("./pages/CV"));
const VFX = lazy(() => import("./pages/VFX"));
const Privacity_Policy = lazy(() => import("./pages/Privacity_Policy"));
const ProyectsPage = lazy(() => import("./pages/Proyects"));
//404
const NotFound = lazy(() => import("./pages/NotFound"));

function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/">
            
              <Route index element={<Index />} />
              <Route path="About" element={<Web />}/>
              <Route path="Proyect/:proyectName" element={<ProyectsPage />}/>
              <Route path="Contact" element={<Contact />}/>
              <Route path="Gallery" element={<Gallery />}/>
              <Route path="Login" element={<Login />}/>
              <Route path="Admin" element={<Admin />}/>
              <Route path="Register_Proyect" element={<Admin_setProyect />}/>
              <Route path="Register_Skill" element={<Admin_setSkill />}/>
              <Route path="Register_Image" element={<Admin_setImages />}/>
              <Route path="CV" element={<CV />}/>
              <Route path="VFX" element={<VFX />}/>
              <Route path="privacy-policy" element={<Privacity_Policy />}/>

              {/* 404 */}
              <Route path="*" element={<NotFound />}/>
          </Route>
        </Routes>
      </BrowserRouter>
  );
}

export default App;
